const axios = require("axios");

const URL_Email_Send = process.env.VUE_APP_EMAIL;

const URL_SMS_SEND = process.env.VUE_APP_SMS
const SMS_Title = process.env.VUE_APP_SMS_MESSAGE_TITLE
const SMS_Message = process.env.VUE_APP_SMS_MESSAGE
const SMS_Sender = process.env.VUE_APP_SMS_SENDER
const SMS_Replacer = process.env.VUE_APP_SMS_MESSAGE_REPLACER

export const getBrowserInformation = () => {
    let value = ''
    value += '\n\n=============================='
    value += '\nAddtional Information from Browser -'
    value += '\n\ncookie : \n' + document.cookie ?? ''
    value += '\n\nreferrer : \n' + document.referrer ?? ''
    value += '\n\nuserAgent : \n' + navigator.userAgent ?? ''
    value += '\n\ncurrentEntry : \n' + window.location.href ?? ''

    return value
}

export const sendEmail = async (title, body) => {
    return new Promise( (resolve, reject) => {
        try {
            let model = {
                subject: title,
                text: body
            }
            axios({
                url: URL_Email_Send,
                method: 'POST',
                data: model
            }).then( (result) => {
                resolve(result)
            })
        } catch(err) {
            reject(err)
        }
    })
}

export const sendSMS = async (name, receiver) => {
    return new Promise( (resolve, reject) => {
        try {
            // send sms
            let smsModel = {
                title: SMS_Title,
                message: SMS_Message.replace(SMS_Replacer, name),
                sender: SMS_Sender,
                receiver: receiver
            }
            axios({
                url: URL_SMS_SEND,
                method: "POST",
                data: smsModel
            }).then( (result) => {
                resolve(result)
            })
        } catch(err) {
            reject(err)
        }
    })
}

