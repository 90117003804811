import { createWebHistory, createRouter } from "vue-router"

import Main from "../views/Main/MainView.vue"
import Event_AL from "../views/Event/AL/Event_AL.vue"
import Event_Message from '../views/Event/Message/Event_Message.vue'
import IntroVideo from '../views/IntroVideo/IntroVideo.vue'
import ContactPage from "@/views/Share/Contact/ContactPage.vue"

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: Main,
      name: "MainView",
    },
    {
      path: "/contact/:key?",
      component: ContactPage,
      name: "ContactPage",
    },
    {
      path: "/event/al",
      component: Event_AL,
      name: "Event_AL"
    },
    {
      path: '/event/m',
      component: Event_Message,
      name: 'Event_Message'
    },
    {
      path: '/intro',
      component: IntroVideo,
      name: "IntroVideo"
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ],
})

export { router }
